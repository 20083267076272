import React from "react";
import MobileProjects from "../components/mobile-projects";

const Projects = () => {
    return(
        <div>
            <MobileProjects/>
        </div>
    )
}

export default Projects;